import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Stack,
  Grid,
  Box,
  Button,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import * as XLSX from "xlsx";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import TableToShowBulkSelectedData from "./TableToShowBulkSelectedData";

const BulkUploadCups = () => {
  const [sheetData, setSheetData] = useState(null);
  const [bulkFile, setBulkFile] = useState(null);
  const [loading, setloading] = useState({ open: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   function to handle drope file
  const onDrop = useCallback((acceptedFiles) => {
    setSheetData(null);
    setloading({ open: true });
    if (
      acceptedFiles[0].type === "application/vnd.ms-excel" ||
      acceptedFiles[0].type === "text/csv" ||
      acceptedFiles[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      acceptedFiles[0].name.split(".")[
        acceptedFiles[0].name.split.lenght - 1
      ] === "xlsx"
    ) {
      setBulkFile(acceptedFiles[0]);
      readExcel(acceptedFiles[0]);
      setloading({ open: false });
    } else {
      // alert("invalid file format! Please upload xlx, xlxs or csv file");
      dispatch(
        openSnackbar(
          "Invalid File Format! Please upload .xlx, .xlxs or .csv file",
          "warning"
        )
      );
      setloading({ open: false });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // function to read file
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((dataFromSheet) => {
      setSheetData(dataFromSheet);
    });
  };

  console.log("===>", sheetData);
  // ====handledownload ================
  const handleDownload = () => {
    const fileUrl = "/assets/invitees_template.xlsx";
    // Create a temporary link element
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;

    // Set the filename for the downloaded file
    downloadLink.setAttribute("invitees_template", "filename.ext");

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up the URL object and link element
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileUrl);
  };
  // ==end of handleDownload ===========

  // === handleupload file bulk upload ====
  const handleUploadFile = async () => {
    dispatch(isLoading(true));
    try {
      //   props.setLoading(true);
      if (bulkFile) {
        // const fileData = new FormData();
        // fileData.append("file", bulkFile);
        const res = await axios.post(`${baseUrl}/cup/upload/cup/by/csv`, {
          uploadedCSV: sheetData,
        });
        if (res.status === 200) {
          dispatch(openSnackbar("Cups are added succesfully", "success"));

          //   props?.setLoading(false);
          setBulkFile(null);
          setSheetData(null);
          dispatch(isLoading(false));
          navigate("/scan-to-add-cup");
        }
      } else {
        // props?.setLoading(false);
        dispatch(isLoading(false));
        alert("Please Select File First");
        dispatch(openSnackbar("Please Select a File First", "warning"));
      }
    } catch (error) {
      console.log("error=>", error);
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(
          openSnackbar(
            "something went wrong with your file download example and fill as instructed then upload,",
            "error"
          )
        );
      }
      dispatch(isLoading(false));
    }
  };

  // ====endl of handleupload ============

  return (
    <>
      <Helmet>
        <title>Bulk Upload Cups | CHIPT NFC</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            Upload Cups in Bulk
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>

        <Grid container>
          {/* <Grid item xs={12}>
            <Typography mt={1} fontWeight={600} fontSize="14px">
              Download the sample to upload cups in bulk
            </Typography>

            
            <Stack mt={1} spacing={2} direction={"row"}>
              <Box height="45px" width="45px">
                <Box component={"img"} src="./assets/Excel.svg" width="100%" />
              </Box>
              <Button
                size="large"
                sx={{
                  display: "block",
                  ml: 4,
                  textTransform: "none",
                  color: "black",
                  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.3)",
                  borderRadius: "4px",

                  height: "50px",
                  width: "150px",
                }}
                onClick={handleDownload}
              >
                Download Here
              </Button>
            </Stack>
          </Grid> */}
          <Grid xs={12}>
            <Typography fontSize="14px" fontWeight={600} textAlign="center">
              Locate the File on Your Computer and Upload Below
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            {...getRootProps()}
            accept=".xlsx, .csv"
            sx={{
              width: "100%",
              height: "12rem",
              border: "2px dashed rgba(0, 128, 220, 0.5)",
              bgcolor: isDragActive ? "#007ea7" : "#00a8e8",
              borderRadius: "24px",
              p: 3,
              cursor: "pointer",
              transition: "all .2s ease",
              "&:hover": {
                bgcolor: "#007ea7",
                scale: ".97",
                borderColor: "white",
              },
              "&:active": {
                scale: "1",
              },
            }}
          >
            <Box
              component="img"
              src={
                bulkFile ? "./assets/Excel.svg" : "./assets/upload-cloud.svg"
              }
              display="block"
              margin="auto"
              width="50px"
            />
            <input
              {...getInputProps({
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                multiple: false,
              })}
            />
            {bulkFile ? (
              ""
            ) : (
              <Typography
                variant="h1"
                fontSize={{ xs: "15px", sm: "22px" }}
                fontWeight="800"
                color="white"
                textAlign="center"
              >
                Drag & Drop
              </Typography>
            )}

            {isDragActive ? (
              <Typography color="white" textAlign="center">
                Drop your file here
              </Typography>
            ) : (
              <Typography color="white" textAlign="center">
                {bulkFile ? `${bulkFile?.name}` : "or select file from device"}
              </Typography>
            )}
            <Typography
              color="rgba(255, 255, 255, 0.3)"
              textAlign="center"
              fontSize="14px"
              mt={1}
            >
              max. 50MB
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Stack direction={"row"} mt={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading.open || !bulkFile}
                disableElevation
                sx={{ color: "white" }}
                onClick={handleUploadFile}
              >
                Upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {sheetData && sheetData.length > 0 ? (
          <>
            <Divider sx={{ mt: 2 }} />
            <Stack mt={2}>
              <Typography variant="h5">Selected File Data</Typography>
              <TableToShowBulkSelectedData data={sheetData} />
            </Stack>
          </>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default BulkUploadCups;
