import React, { useEffect, useState } from "react";
import {
  Stack,
  Paper,
  Avatar,
  Button,
  Box,
  Typography,
  Switch,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// components
import axios from "axios";
import { useDispatch } from "react-redux";

import ConfirmationModal from "../../../components/modal/ConfirmationModal";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import Label from "../../../components/label/Label";

// sections

// mock

// ----------------------------------------------------------------------

// refactorCustomerDAta
function refactorCustomerDetails(customerArray) {
  const tempArray = [];
  customerArray.forEach((customerData) => {
    tempArray.push({
      rowIndex: tempArray.length + 1,
      _id: customerData._id,
      customerName: `${customerData.firstname ? customerData.firstname : "-"} ${
        customerData.middlename ? customerData.middlename : ""
      } ${customerData.lastname ? customerData.lastname : ""}`,
      email: customerData.primaryEmail,
      contactNumber: `(+${customerData.countryCode})${customerData.primaryContactNumber}`,
      address: `${customerData.plotnumber ? customerData.plotnumber : ""} ${
        customerData.address ? customerData.address : ""
      } ${customerData.city ? customerData.city : ""} ${
        customerData.country ? customerData.country : ""
      } ${customerData.zipCode ? customerData.zipCode : ""} `,
      zipCode: customerData.zipCode,
      location: customerData.location,
      profilePicture: customerData.profilePicture,
      isActive: customerData.isActive,
      isVerified: customerData.isVerified,
      isBlocked: customerData.isActive,
    });
  });

  return tempArray;
}
// ----------------------------------------------------------------------

const CustomersDetails = ({ title, getApiRoute }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [allCustomers, setAllCustomers] = useState([]);

  const [openConfirmationModal, setopenConfirmationModal] = useState(false);

  const [activeId, setactiveId] = useState(null);

  const [confirmationMsg, setConfirmationMsg] = useState("");

  const handleCloseConfirmationModal = () => {
    setactiveId(null);
    setopenConfirmationModal(false);
    setConfirmationMsg("");
  };

  // customize filter tool
  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  // custome toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "rowIndex", headerName: "ID", width: 90 },
    {
      field: "customerName",
      headerName: "Customer",
      width: 220,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <>
              <Avatar
                alt={params?.row?.customerName}
                src={params?.row?.profilePicture?.url}
              />
              &nbsp;&nbsp; &nbsp;&nbsp;
              <Typography variant="subtitle2" noWrap>
                {params?.row?.customerName}
              </Typography>
            </>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      align: "left",
      width: 220,
      editable: false,
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      width: 150,
      editable: false,
    },
    {
      field: "isVerified",
      headerName: "Verified",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Typography variant="body2" component={"p"}>
              {params.row.isVerified ? "Yes" : "No"}
            </Typography>
          </>
        );
      },
    },

    {
      field: "isActive",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Label
              color={(params.row.isActive === false && "error") || "success"}
            >
              {params.row.isActive ? "active" : "inactive"}
            </Label>
          </>
        );
      },
    },
    {
      field: "isBlocked",
      headerName: "Blocked",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={!params.row.isBlocked}
              disabled={!params.row.isVerified}
              onChange={() =>
                handleBlockOpenModal(params.row._id, params.row.isActive)
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
    {
      field: "view",
      headerName: "Preview",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(`/customers/customerProfile/${params.row._id}`)
              }
            >
              View
            </Button>
          </>
        );
      },
    },
  ];

  const allRowDataIncludingIndex =
    allCustomers && allCustomers.length > 0
      ? refactorCustomerDetails(allCustomers)
      : [];

// function to fetch customer list
  const getCustomersList = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}${getApiRoute}`);
      console.log("==>", res.data);
      if (
        res.status === 200 &&
        res.data.customers &&
        res.data.customers.length > 0
      ) {
        setAllCustomers(res?.data?.customers);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log({ error });
      dispatch(isLoading(false));
    }
  };

  // ===========handleblock user ===
  const handleBlockOpenModal = (id, actionType) => {
    setactiveId(id);
    setConfirmationMsg(actionType ? "block" : "unblock");
    setopenConfirmationModal(true);
  };

  const handleBlock = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `${baseUrl}/customer/enable/disable/${activeId}`
      );
      handleCloseConfirmationModal();
      dispatch(openSnackbar("user status updated", "success"));
      dispatch(isLoading(false));
      getCustomersList();
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      handleCloseConfirmationModal();
      dispatch(isLoading(false));
    }
  };
  // ===============================
  useEffect(() => {
    if (getApiRoute) {
      getCustomersList();
    }
  }, []);

  return (
    <>
      {/* <Stack>
        <Typography variant="h4">{title}</Typography>
      </Stack> */}
      {allCustomers.length > 0 ? (
        <Box sx={{ minHeight: 400, width: "100%" }} >
          <DataGrid
            rows={
              allRowDataIncludingIndex.length > 0
                ? allRowDataIncludingIndex
                : []
            }
            columns={columns}
            getRowId={(row) => row?._id}
            slots={{ toolbar: CustomizedToolbarLayout }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              textTransform: "capitalize",
              minHeight: "250px",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        "No data found"
      )}

      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAction={handleBlock}
        warningMsg={confirmationMsg}
        modalFor="customer"
      />
    </>
  );
};

export default CustomersDetails;
