import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Iconify from "../../../components/iconify/Iconify";
import { fDateTime } from "../../../utils/formatTime";
import VendorStockInfoOnProfilePage from "./VendorStockInfoOnProfilePage";
import VendorStock from "./VendorStock";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import { googleMapApiKey } from "../../../utils/keys";

const VendorProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { vendorId } = useParams();
  const [vendorData, setVendorData] = useState(null);
  const [vendorStockDetail, setVendorStockDetail] = useState(null);
  const [map, setmap] = useState(null);

  const getVendorData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/vendor/profile/${vendorId}`);
      // console.log("venodre data=>", res?.data);
      setVendorData(res.data.user);
      // dispatch(openSnackbar(res?.data?.message, "success"));
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(isLoading(false));
    }
  };
  // ===get stock info =====
  const getStockDetails = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/vendor/${vendorId}/store/stock`);
     
      setVendorStockDetail(res?.data?.vendorsStocks);
      dispatch(openSnackbar("success getting", "success"));
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(isLoading(false));
    }
  };
  // =======================
  useEffect(() => {
    if (vendorId) {
      getVendorData();
      getStockDetails();
    }
  }, []);


  useEffect(() => {
   
    if (map && vendorData?.location) {
      (() => {
        // Function Body...
        map.panTo(vendorData?.location);
      })();
    }
  }, [map])
  
  return (
    <>
      <Helmet>
        <title> Admin | Vendor Profile </title>
      </Helmet>

      <Container>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
          mb={1}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon />{" "}
          <Typography variant="body2" component={"span"}>
            Back
          </Typography>
        </Stack>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{
            backgroundRepeat: "no-repeat",
            backdropFilter: "blur(10px)",
            minHeight: "100px",
            bgcolor: "primary.dark",
            position: "relative",
          }}
          alignItems={"center"}
          justifyContent={"start"}
        >
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              color: "white",
            }}
          >
            <Stack direction={"row"} spacing={1} mt={2}>
              <Typography fontSize={"10px"}>
                Registered Id : {vendorId}
              </Typography>
              <Typography fontSize={"10px"}>
                Since : {fDateTime("01-09-2023 12:10 AM")}
              </Typography>
            </Stack>
          </Box>
          <img
            src={vendorData?.profilePicture?.url}
            alt={vendorData?.name}
            style={{ width: { md: "280px", xs: "100%" }, height: "180px" }}
          />
          <Stack p={0.5}>
            <Typography
              variant="h2"
              textAlign={"center"}
              fontFamily={"monospace"}
              color={"white"}
              fontWeight={"bolder"}
              sx={{ textShadow: "5px 5px rgba(15 ,10, 85, 0.5)" }}
            >
              {vendorData?.name}
            </Typography>

            <Typography
              variant="body2"
              fontSize={"20px"}
              fontFamily={"monospace"}
              component={"p"}
              color={"white"}
              textAlign={"center"}
              p={0.5}
            >
              Address:{" "}
              <Typography
                variant="body2"
                // fontSize={"20px"}
                fontFamily={"monospace"}
                component={"span"}
                textAlign={"center"}
              >
                {vendorData?.plotNumber} {vendorData?.address}{" "}
                {vendorData?.city} {vendorData?.state} , {vendorData?.zipCode}
              </Typography>
            </Typography>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              display: { xs: "none", md: "block" },
              top: 5,
              right: 10,
              color: "white",
            }}
          >
            <Stack direction={{ md: "row", xs: "column" }} spacing={1}>
              <Typography fontSize={"10px"}>
                Registered Id : {vendorId}
              </Typography>
              {/* <Typography fontSize={"10px"}>
                Since : {fDateTime("01-09-2023 12:10 AM")}
              </Typography> */}
            </Stack>
          </Box>
        </Stack>
        <Box
          mt={2}
          sx={{
            bgcolor: "rgba(237,239,241,0.5)",

            borderRadius: "10px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <List
                  sx={{
                    width: "100%",
                    direction: "column",
                    display: { xs: "block", md: "flex" },
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(242,85,120,1)" }}>
                        <Iconify icon="ph:user" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Vendor"
                      sx={{ wordWrap: "break-word" }}
                      secondary={vendorData?.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                        <Iconify icon="typcn:phone" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Phone"
                      secondary={`(+${vendorData?.countryCode})${vendorData?.contactNumber}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                        <Iconify icon="dashicons:email" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Email"
                      secondary={vendorData?.email}
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>

          {/* <Stack
            component={"iframe"}
            width={"100%"}
            height={"200px"}
            border={"0"}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28487.99667493183!2d80.87705081703808!3d26.808141862760177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfc20efc38085%3A0xae8f7527ef945bc!2sAlambagh%2C%20Lucknow%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1697095276617!5m2!1sen!2sin"
          /> */}
          
          <LoadScript googleMapsApiKey={googleMapApiKey}>
            <GoogleMap
              // center={{
              //   lat: vendorData?.location[0] * 1,
              //   lng: vendorData?.location[1] * 1,
              // }}
              center={{
                lat: 26.7991202,
                lng: 80.8854058,
              }}
              zoom={18}
              mapContainerStyle={{ width: "100%", height: "400px" }}
              onLoad={(map) => setmap(map)}
              // onClick={handleMarkerClick}
              draggable={false}
            >
              {vendorData?.location && (
                <Marker
                  position={{
                    lat: 26.7991202,
                    lng: 80.8854058,
                  }}
                />
              )}
            </GoogleMap>
          </LoadScript>
        </Box>
        {/* <Stack mt={2}>
          <Typography variant="h4">Overview</Typography>
          <List
            sx={{
              width: "100%",
              direction: "column",
              display: { xs: "block", md: "flex" },
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "info.dark" }}>
                  <Iconify icon="bx:coffee-togo" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Daily Orders"
                sx={{ wordWrap: "break-word" }}
                secondary={"78596"}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "primary.dark" }}>
                  <Iconify icon="tabler:user-filled" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Customers Visits" secondary="968532" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "error.dark" }}>
                  <Iconify icon="bx:coffee-togo" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Returned" secondary="789465" />
            </ListItem>
          </List>
        </Stack> */}
        {/* <Stack mt={1}>
          <VendorStockInfoOnProfilePage />
        </Stack> */}
        <Stack>
          <Grid
            container
            spacing={1}
            display={"flex"}
            direction={"row"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Grid item xs={12} md={7}>
              <VendorStock data={vendorStockDetail} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default VendorProfile;
